<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin phiếu xuất kho từ kiểm tra tồn kho'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <!-- LEFT INPUT CONTROLS -->
          <b-col lg="9" md="9" sm="12">
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group label-class="font-weight-bolder">
                  <label>Mã phiếu:</label>
                  <p>{{ codeStockBill }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group class="required-control">
                  <label>Ngày xuất kho:</label>
                  <p>{{ $v.form.dateImport.$model }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <label>Tổng tiền:</label>
                  <p class="mt-2">{{ formatMoney(totalPrice) }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group label-class="font-weight-bolder">
                  <label>Người xuất:</label>
                  <p>{{ createBy }}</p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group class="required-control" :disabled="!isEdit">
                  <label>Kho:</label>
                  <vue-autosuggest
                    class="border-radius-none"
                    :suggestions="filteredOptions"
                    @selected="onSelectedStore"
                    :limit="200"
                    @input="onInputChange"
                    :input-props="inputPropStore"
                    v-model="$v.searchStore.$model"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      <div style="{ display: 'flex', color: 'navyblue'}">
                        {{ suggestion.item.suggestionName }}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="12" md="12" sm="12" :disabled="!isEdit">
                <b-form-group>
                  <label>Ghi chú:</label>
                  <b-form-textarea
                    size="sm"
                    v-model="description"
                    :placeholder="'Thêm nội dung ghi chú...'"
                    :rows="3"
                    :max-rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          "
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 15%">
                Tên sản phẩm
              </th>
              <th scope="col" class="title-center" style="width: 5%">Tồn</th>
              <th scope="col" class="title-center" style="width: 20%">IMEI</th>
              <th scope="col" class="title-center" style="width: 10%">
                Số lượng
              </th>
              <th scope="col" class="title-center" style="width: 18%">
                Đơn giá
              </th>
              <th scope="col" class="title-center" style="width: 14%">
                Thành tiền
              </th>
              <th scope="col" class="title-center" style="width: 13%">
                Chiết khấu
              </th>
              <th scope="col" class="title-center" style="width: 5%"></th>
            </tr>
          </thead>

          <tbody v-for="item in listProductStock" :key="item.id">
            <ImportStock
              v-bind:productItem="item"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="isEdit"
            />
          </tbody>
          <tr>
            <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
            <td class="tdTextAlignPrice">{{ totalQuantity }}</td>
            <td></td>
            <td class="tdTextAlignPrice">{{ formatMoney(totalPrice) }}</td>
            <td class="tdTextAlignPrice">
              {{ formatMoney(totalDiscount) }}
            </td>
          </tr>
        </table>

        <div class="col-md-12">
          <div class="row mb-5 mt-5">
            <b-button
              style="fontweight: 600; width: 70px"
              v-show="listProductStock.length > 0"
              v-bind:style="btnCreate"
              type="button"
              variant="primary"
              size="sm"
              @click="onSubmit"
              v-if="isEdit"
            >
              Lưu
            </b-button>
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style>
.title-center {
  text-align: center;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.input-group-append {
  cursor: pointer;
  padding-top: 5.5px;
}

.input-group-append:hover i {
  color: #3699ff;
}

.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
  font-weight: 600;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { currencyMask, unMaskPrice } from '@/utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
      },
      form: {
        dateImport: '',
      },
      isNew: true,
      dateCreate: '',
      listInventories: [],
      listProduct: [],
      count: 0,
      description: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inventorySelected: null,
      formater: {
        currency: currencyMask,
      },
      isSearching: false,
      inputPropStore: {
        class: 'autosuggest__input',
        placeholder: 'Chọn kho',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      codeStockBill: '',
      createBy: '',
      idStock: null,
      isEdit: false,
    };
  },
  validations: {
    form: {
      dateImport: { required },
    },
    searchStore: { required },
  },
  components: {
    KTCodePreview,
    ImportStock,
  },
  created() {},
  mounted() {
    this.dateCreate = moment().format('HH:mm DD/MM/YYYY');
    this.$v.form.dateImport.$model = moment().format('DD/MM/YYYY');
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu xuất kho từ kiểm kê tồn kho', route: '/export-check-sheet-stocks' },
      { title: 'Chi tiết phiếu xuất kho' },
    ]);
    this.getStockById();
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
  },
  methods: {
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onSubmit: async function () {
      this.isNew = false;
      if (this.searchStore.trim() === '') {
        alert(`Vui lòng chọn kho!`);
        return;
      }
      const listPro = this.listProductStock.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
        };
      });
      const data = {
        id: this.idStock,
        storeId: this.inventorySelected,
        description: this.description,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD'
            )
          : '',
        listProduct: listPro,
      };
      ApiService.put('check-stock', data)
        .then(({ data }) => {
          this.isNew = true;
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.$router.push({
              name: 'list-export-check-sheet-stock',
            });
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.isNew = true;
          this.makeToastFaile(response.$error);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = unMaskPrice(item.price);
          this.listProductStock[index].quantity = item.quantity;
          this.listProductStock[index].discount = unMaskPrice(item.discount);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    onChangeStore() {
      this.listProductStock = [];
      this.fetchAccountants('cash');
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.inventorySelected = option.item.id;
      this.inputSearchProductProp.disabled = false;
      this.onChangeStore();
    },
    onInputChange(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = true;
      }
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    
    getStockById() {
      const id = this.$route.query.id;
      ApiService.get(`warranty-stock/${id}`)
        .then(({ data }) => {
          let dataRes = data.data;
          this.codeStockBill = dataRes.code;
          this.dateCreate = moment(String(dataRes.createdAt)).format(
            'HH:mm DD/MM/YYYY'
          );
          this.$v.form.dateImport.$model = moment(
            String(dataRes.importDate)
          ).format('DD-MM-YYYY');
          this.inventorySelected = dataRes.storeId;
          this.searchStore = dataRes.storeName;
          this.totalPrice = dataRes.totalAmount;
          this.description = dataRes.description;
          this.idStock = dataRes.id;
          this.createBy = dataRes.createdBy;
          this.listProductStock = [];
          dataRes.listDetail.forEach((element) => {
            this.count++;
            let listImei = element.productImei.split(',');
            let stt = 1;
            let formatImei = '';
            listImei.forEach((element, index) => {
              formatImei += stt + ')  ' + element;
              if (index !== listImei.length - 1) {
                formatImei += ',';
              }
              stt++;
            });
            const dataItem = {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              formatImei: formatImei.replace(/[,]/g, '\n').replace(/[ ]/g, ''),
              totalQuantityInStock:
                element.totalQuantityInStock != null
                  ? element.totalQuantityInStock
                  : 0,
              discount: element.discount ? element.discount : 0,
              barCode: element.barCode ? element.barCode : '',
              productCode: element.productCode ? element.productCode : '',
            };
            this.listProductStock.push(dataItem);
          });
        })
        .catch((response) => {
          this.makeToastFaile(response.$error);
        });
    },
  },
};
</script>
